import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RecipesByAuthor = ({data}) => {
  const recipes = data.allContentfulRecipe.edges;
  const thisAuthor = data.allSitePage.nodes[0].context.indexedAuthor;
  
  return (
  <Layout>
    <SEO title={`${thisAuthor}'s Recipes`} />
    <div className="contentContainer">
      <h1 className="py-5">{`${thisAuthor}'s Recipes`}</h1>
      <div className="px-4">
      { recipes
          .map(({ node: recipe }, index) => (
            <div>
              <div className="px-2 py-4">
                <Link to={`/${recipe.slug}`}>
                  <span className="author">{`${recipe.author}'s`}</span>
                  <h2>{recipe.title}</h2>
                </Link>
              </div>
              <hr />
            </div>
            ))}
      </div>
    </div>
  </Layout>
  )
}

export default RecipesByAuthor

export const pageQuery = graphql`
    query RecipesByAuthorPageQuery($indexedAuthor: [String]) {
      allSitePage(filter: {context: {indexedAuthor: {in: $indexedAuthor}}}) {
        nodes {
          path
          context {
            indexedAuthor
          }
        }
      }    
      allContentfulRecipe(filter: {author: {in: $indexedAuthor}}) {
        edges {
          node {
            title
            tags
            author 
            slug
            addedDate(formatString: "MM-DD-YYYY")
          }    
        }
      }    
    }
`